<template>
  <div :class="$style.home">
    <BjSearch @refresh="getList" @init="init">
      <BjInput
        v-model="search.keyword"
        :class="$style.input"
        v-bind="layout"
        allow-clear
        label="搜索作者"
        :placeholder="search.search_type === 1 ? '请输入作者姓名' : '请输入作者账号'"
        @pressEnter="getList"
      >
        <a-select slot="addonBefore" v-model="search.search_type" style="width: 100px">
          <a-select-option :value="1"> 作者姓名 </a-select-option>
          <a-select-option :value="2"> 关联账号 </a-select-option>
          <i slot="suffixIcon" class="ri-arrow-down-s-line suffix" />
        </a-select>
      </BjInput>
      <BjSelect
        v-model="search.account_type"
        v-bind="layout"
        key-field="id"
        inner-search
        label="作者类型"
        placeholder="请选择类型"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
    </BjSearch>
    <div :class="$style.table">
      <a-row>
        <a-col span="12" :class="$style.title"> 作者列表 </a-col>
        <a-col span="12" class="text-right">
          <a-dropdown :trigger="['click']">
            <BjButton v-permission="'system.account.author-create'" type="primary">
              添加作者
              <i :class="$style.drop" class="ri-arrow-down-s-line right" />
            </BjButton>
            <a-menu slot="overlay" class="list-drop">
              <a-menu-item>
                <BjLink @click="onAdd()"> <i :class="$style.icon" class="ri-add-line mr-8" />添加内部作者 </BjLink>
              </a-menu-item>
              <a-menu-item>
                <BjLink> <i :class="$style.icon" class="ri-links-line mr-8" />关联外部作者 </BjLink>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-col>
      </a-row>
      <a-table class="mt-20" :columns="columns" :loading="loading" :data-source="data" :pagination="false" row-key="id">
        <template #img="item">
          <div :class="$style.avatar">
            <img :src="item.avatar" />
          </div>
        </template>
        <template #action="item">
          <div v-if="!item.is_ungrouped">
            <bj-link v-permission="'system.account.author-edit'" type="primary" @click="onEdit(item)"> 编辑 </bj-link>
            <a-popconfirm
              title="确认删除该作者吗？删除之后，作者创作的内容不会被删除，作者信息会被标记为已删除状态。"
              placement="topLeft"
              @confirm="onDelete(item)"
            >
              <bj-link v-permission="'system.account.author-delete'" type="danger"> 删除 </bj-link>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { systemService } from '@/service'

const service = new systemService()

export default {
  name: 'Home',
  data() {
    return {
      data: [],
      search: {
        keyword: null,
        search_type: 1,
        account_type: '0',
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      total: 0,
      page: 1,
      pageSize: 20,
      loading: false,
      typeData: [
        {
          id: '0',
          name: '全部类型',
        },
        {
          id: '1',
          name: '内部作者',
        },
        {
          id: '2',
          name: '外部作者',
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '作者头像',
          scopedSlots: {
            customRender: 'img',
          },
        },
        { title: '作者姓名', dataIndex: 'real_name' },
        { title: '对应账号', dataIndex: 'account' },
        {
          title: '作者类型',
          dataIndex: 'account_type',
          customRender: text => (text === 1 ? '内部作者' : '外部作者'),
        },
        {
          title: '文化资源',
          dataIndex: 'poc_num',
          customRender: (text, record) => (
            <bj-link onClick={() => this.onPoc(record)} type={'primary'}>
              {text}
            </bj-link>
          ),
        },
        {
          title: '文化内容',
          dataIndex: 'content_num',
          customRender: (text, record) => (
            <bj-link onClick={() => this.onContent(record)} type={'primary'}>
              {text}
            </bj-link>
          ),
        },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getAuthor({
          ...this.search,
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    onEdit(item) {
      this.$router.push({
        name: 'systemAuthorAdd',
        params: {
          id: item.id,
        },
      })
    },
    onAdd() {
      this.$router.push({
        name: 'systemAuthorAdd',
        params: {
          id: 'add',
        },
      })
    },
    async onDelete(item) {
      try {
        await service.deleteAuthor({
          id: item.id,
        })
        this.$message.success('删除成功')
        this.page = 1
        this.getList()
      } catch (e) {}
    },
    onContent(item) {
      this.$router.push({
        name: 'contentManage',
        params: {
          author_id: item.id,
        },
      })
    },
    onPoc(item) {
      this.$router.push({
        name: 'resourcesPoint',
        params: {
          author_id: item.id,
        },
      })
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    init() {
      this.search = {
        keyword: null,
        search_type: 1,
        account_type: '0',
      }
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  :global {
    .ant-select-arrow {
      top: 40%;
    }
  }

  .input {
    :global {
      .ant-input-group-wrapper {
        position: relative;
        top: 4px;
      }

      .ant-select-selection__rendered {
        margin-left: 15px;
        color: #5c5c5c;
      }
    }
  }

  .table {
    min-height: calc(100vh - 240px);
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .title {
      color: #000;
      font-size: 16px;
    }

    .avatar {
      width: 32px;
      height: 32px;
      overflow: hidden;
      border-radius: 50%;

      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
      }
    }
  }
}

.icon {
  font-size: 20px;
  vertical-align: -4px;
}

.drop {
  margin-right: 0 !important;
  margin-left: 8px;
}
</style>
